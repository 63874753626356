import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule)
      },
      {
        path: 'dash-vod',
        loadChildren: () => import('./modules/dash-vod/dash-vod.module').then((m) => m.DashVodModule)
      },
      {
        path: 'dash-ls',
        loadChildren: () => import('./modules/dash-ls/dash-ls.module').then((m) => m.DashLsModule)
      },
      {
        path: 'hls-vod',
        loadChildren: () => import('./modules/hls-vod/hls-vod.module').then((m) => m.HlsVodModule)
      },
      {
        path: 'hls-ls',
        loadChildren: () => import('./modules/hls-ls/hls-ls.module').then((m) => m.HlsLsModule)
      },
      {
        path: '**',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
