import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-filters',
  templateUrl: './page-filters.component.html',
  styleUrls: ['./page-filters.component.scss']
})
export class PageFiltersComponent implements OnInit {
  public showFilters = false;
  public finalAkamiUrl = '';
  public currentUrl: String;
  public cdnCloudfront = environment.CLOUDFRONT_URL;

  @Input() isLS: boolean = false;
  @Input() qualities: Array<any> = [];
  @Output() onStart = new EventEmitter<any>();
  @Output() onQualityChangeEvent = new EventEmitter<any>();

  constructor(private router: Router) {
    this.currentUrl = this.router.url.replace('/', '');
  }

  ngOnInit(): void {
      if (this.isLS) {
        this.finalAkamiUrl = environment.AKAMAI_VIVIDAS_LS_URL;
      } else {
        this.finalAkamiUrl = environment.AKAMAI_VIVIDAS_VOD_URL;
      }
  }

  public filtersForm = new FormGroup({
    quality: new FormControl('-1'),
    streamKey: new FormControl(''),
    partner: new FormControl('partner1'),
    cdn: new FormControl(this.cdnCloudfront),
  });

  public onSubmit() {
    this.onStart.emit(this.filtersForm.value);
  }

  public onQualityChange(field: any) {
    this.onQualityChangeEvent.emit(field.target.value);
  }

  public toggleFilters() {
    this.showFilters = !this.showFilters;
  }
}
