<form [formGroup]="filtersForm" (ngSubmit)="onSubmit()">
    <div class="icon" (click)="toggleFilters()">
        Filter 
        <img src="assets/img/filter.png" alt="Filters">
    </div>

    <div class="button-container hide-large">
        <button id="start-mobile" [attr.data-test]="'start-mobile-' + currentUrl" type="submit">Start</button>
    </div>

    <div class="fields" [ngClass]="{'show-filters': showFilters}">
        <div class="field">
            <label for="feature">Partner Features</label>
            <select data-test="feature" name="feature" id="feature" formControlName="partner">
                <option data-test="partner-1" value="partner1">VIV32 Encryption & Session-Based Encryption</option>
                <option data-test="partner-2" value="partner2">VIV32 Encryption only</option>
                <option data-test="partner-3" value="partner3">Session-Based Encryption only</option>
            </select>
        </div>

        <div class="field">
            <label for="cdn">CDN</label>
            <select data-test="cdn-dropdown" name="cdn" id="cdn" formControlName="cdn">
                <option data-test="cdn-cloudfront" value="{{ cdnCloudfront }}">CloudFront</option>
                <option data-test="cdn-akamai" value="{{ finalAkamiUrl }}">Akamai</option>
            </select>
        </div>

        <div class="field" *ngIf="!isLS">
            <label for="quality">Quality</label>
            <select data-test="quality" formControlName="quality" name="quality" id="quality" (change)="onQualityChange($event)">
                <option data-test="quality-auto" value="-1">Auto Quality</option>

                <option *ngFor="let quality of qualities" [attr.data-test]="quality.name" value="{{quality.value}}">{{quality.text}}</option>
            </select>
        </div>

        <div class="field" *ngIf="isLS">
            <label for="streamKey">Stream Key</label>
            <input data-test="streamKey" formControlName="streamKey" name="streamKey" id="streamKey" />
        </div>
    </div>

    <div class="button-container hide-small">
        <button id="start-web" [attr.data-test]="'start-web-' + currentUrl" type="submit">Start</button>
    </div>
</form>
