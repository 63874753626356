export const environment = {
    IS_IBC: false,
    IS_BITMOVIN: true,
    CDN_SERVER: 'https://proxybitmovin.vividas.tech',
    API_KEY: 'QWYvi44Jc73WSGjlgTtZlanvPdRl4XDg8ky6iwBw',
    HIDEN_CAPABILITIES: ['dash-vod', 'hls-vod'],
    ENCRYPTION_SERVER: 'https://proxybitmovin.vividas.tech',
    CLOUDFRONT_URL: 'https://d1wcvgr74fnzn8.cloudfront.net',
    AKAMAI_VIVIDAS_VOD_URL: 'https://bitmovin-vod.akamaized.net',
    AKAMAI_VIVIDAS_LS_URL: 'https://bitmovin-FORMAT-ls-vividas.akamaized.net',
    HLSJS: 'https://s3.ap-southeast-2.amazonaws.com/build-artifacts.vividas.wize.mx/hlsjs/hls.js',
    DASHJS: 'assets/js/dash.all.debug.js',
    HLS_VOD_MANIFEST: 'bcd4911a-94bd-45dc-8925-aba967704561/bcd4911a-94bd-45dc-8925-aba967704561_sintel.m3u8',
};
