<div class="main-container">
    <header>
        <div>
        <h2 data-test="brand-text"><a routerLink="/">V Technologies</a></h2>
        </div>
        <div class="logo">
            <img data-test="logo" src="assets/img/logo.png" alt="V Technologies logo">
        </div>
        <div class="second-logo">
            <ng-container *ngIf="isBitmovin">
                <img src="assets/img/bitmovin.png" alt="Bitmovin Logo">
            </ng-container>
        </div>
    </header>

    <router-outlet></router-outlet>

    <footer data-test="footer">
        <p>
            <b>V TECHNOLOGIES  </b><span>|</span>
        </p>
        <p>
            52 Victoria Street,Sydney NSW 2021 Australia  <span>|</span>
        </p>
        <p>
            Tel: +61 2 8221 8805  <span>|</span>
        </p>
        <p>
            © 2023 V Technologies <span>|</span>
        </p>
        <p>
            Powered by V Technologies
        </p>
    </footer>
</div>
