import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageFiltersComponent } from './components/page-filters/page-filters.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    PageHeaderComponent,
    PageFiltersComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    PageHeaderComponent,
    PageFiltersComponent,
    ReactiveFormsModule
  ]
})
export class SharedModule { }
